<template>
  <fki-container
    fixed
    top="131px"
    bottom="0"
    left="0"
    column
    width="100%"
  >
    <fki-container-header
      :color="white"
      min-height="100px"
      size="30px"
      title="GLOBALDATA® ACCOUNT SETUP"
    />
    <div
      class="fki-contact-wrapper"
    >
      <div
        class="fki-contact-box"
      >
        <div
          class="fki-form-container"
        >
          <p style="color:#fff; font-size:13px;">
            Be sure your system meets the following minimum
            requirements before installing the globalDATA&reg;
            Download Service:
            <br>
            <br>
            &bull; (32bit or 64 bit) Windows 10 or higher, Mac OS X 10.14 or higher, Linux kernel 2.6.4
            or higher <br>
            &bull; 4 GB RAM <br>
            &bull; Administrative privileges on designated
            workstation/server
            <br>
            &bull; Open Firewall Ports - (Outgoing) TCP ports
            8884-8886 &amp; Optional UDP port 8886 <br>
            &bull; IPs For This Service are available upon request
          </p>
          <form
            id="send-message"
            name="messageForm"
          >
            <div class="row">
              <div
                class="col col-left"
              >
                <input
                  v-model="eMessage.name"
                  type="text"
                  placeholder="Name*"
                  required
                  autofocus
                  @focus="closeDropdown()"
                  @blur="v$.eMessage.name.$touch()"
                >
                <p
                  v-for="error of v$.eMessage.name.$errors"
                  :key="error.$uid"
                  class="error"
                >
                  {{ error.$message }} for name
                </p>
                <input
                  v-model="eMessage.company"
                  type="text"
                  placeholder="Company*"
                  required
                  @focus="closeDropdown()"
                  @blur="v$.eMessage.company.$touch()"
                >
                <p
                  v-for="error of v$.eMessage.company.$errors"
                  :key="error.$uid"
                  class="error"
                >
                  {{ error.$message }} for company
                </p>
                <input
                  v-model="eMessage.state"
                  type="text"
                  placeholder="State/Province/Region"
                  @focus="closeDropdown()"
                >
                <div class="recaptch">
                  <vue-recaptcha
                    ref="recaptcha"
                    v-model="gRecaptchaResponse"
                    sitekey="6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3"
                    theme="light"
                    @verify="verifyGRe"
                  />
                </div>
              </div>
              <div
                class="col col-right"
              >
                <input
                  v-model="eMessage.speed"
                  hidden
                  placeholder=""
                >
                <input
                  v-model="eMessage.phone"
                  type="text"
                  placeholder="Phone*"
                  required
                  @focus="closeDropdown()"
                  @blur="v$.eMessage.phone.$touch()"
                >
                <p
                  v-for="error of v$.eMessage.phone.$errors"
                  :key="error.$uid"
                  class="error"
                >
                  {{ error.$message }} for phone
                </p>
                <input
                  v-model="eMessage.email_server"
                  type="email"
                  placeholder="Email Address for Server Access*"
                  required
                  @focus="closeDropdown()"
                  @blur="v$.eMessage.email_server.$touch()"
                >
                <p
                  v-for="error of v$.eMessage.email_server.$errors"
                  :key="error.$uid"
                  class="error"
                >
                  {{ error.$message }} for email
                </p>
                <input
                  v-model="eMessage.email_notification"
                  type="email"
                  placeholder="Email Address for Notifications"
                  @focus="closeDropdown()"
                >
                <input
                  v-model="eMessage.email_notification2"
                  type="email"
                  placeholder="Email Address for Notifications"
                  @focus="closeDropdown()"
                >
                <input
                  v-model="eMessage.speed"
                  type="hidden"
                >
                <div class="fki-dropdown">
                  <div
                    v-if="showDepartmentDropdown"
                    class="fki-dropdown-overlay"
                    @click="closeDropdown()"
                  />
                  <p
                    v-if="!showDepartmentDropdown"
                    class="right-arrow"
                    @click="openDropdown()"
                  >
                    {{ eMessage.speed }}
                  </p>
                  <div
                    class="fki-dropdown-content"
                    :class="{open:showDepartmentDropdown}"
                  >
                    <p
                      class="fki-option"
                      @click="selectValue('1-5 Mbs')"
                    >
                      1-5 Mbs
                    </p>
                    <p
                      class="fki-option"
                      @click="selectValue('5-15 Mb')"
                    >
                      5-15 Mb
                    </p>
                    <p
                      class="fki-option"
                      @click="selectValue('15-25 Mbs')"
                    >
                      15-25 Mbs
                    </p>
                    <p
                      class="fki-option"
                      @click="selectValue('25-50 Mbs')"
                    >
                      25-50 Mbs
                    </p>
                    <p
                      class="fki-option"
                      @click="selectValue('50-100 Mbs')"
                    >
                      50-100 Mbs
                    </p>
                    <p
                      class="fki-option"
                      @click="selectValue('100-200 Mbs')"
                    >
                      100-200 Mbs
                    </p>
                    <p
                      class="fki-option"
                      @click="selectValue('200-300 Mbs')"
                    >
                      200-300 Mbs
                    </p>
                    <p
                      class="fki-option"
                      @click="selectValue('Over 300 Mbs')"
                    >
                      Over 300 Mbs
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col col-left col-xs-12 col-sm-12 col-md-6 col-lg-6"
              >
                <button
                  type="button"
                  class="button"
                  :disabled="v$.$errors.length"
                  :class="v$.$errors.length? 'disabled' : ''"
                  @click.stop="eMessageSubmit(eMessage)"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
  <fki-container-popup
    :is-active="popupResponseOpen"
    overlay
    persistent
    color="#424242"
    width="40%"
    @close="closePopupResponse"
  >
    <div
      class="fki-email-message-response"
      :class="messageReponse.error? 'error': 'success'"
    >
      <h3 v-html=" messageReponse.message" />
    </div>
  </fki-container-popup>
</template>

<script>
import Api from '@/services/api'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { FKIFooterNav } from '@/components/FKINav'
import { FKIContainer, FKIContainerHeader, FKIContainerPopup } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'GdAccountSetup',
  components: {
    'fki-container': FKIContainer,
    'fki-container-popup': FKIContainerPopup,
    'fki-container-header': FKIContainerHeader,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'vue-recaptcha': VueRecaptcha
  },
  emits: ['isLoading', 'setBackgrounds'],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      messageReponse: {},
      gRecaptchaResponse: null,
      popupResponseOpen: false,
      isLoading: false,
      center: null,
      categories: [],
      subjects: [],
      showDepartmentDropdown: false,
      showSubjectDropdown: false,
      siteKey: '6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3',
      eMessage: {
        name: null,
        speed: 'Please Select A Connection Speed',
        phone: null,
        email_server: null,
        email_notification: '',
        email_notification2: '',
        country: '',
        state: '',
        gRecaptchaResponse: null
      }
    }
  },
  validations () {
    return {
      eMessage: {
        name: { required },
        company: { required },
        phone: { required },
        email_server: { required, email }
      }
    }
  },
  computed: {},
  async created () {
  },
  methods: {
    async verifyGRe (response) {
      this.gRecaptchaResponse = response
    },
    async eMessageSubmit (fields) {
      // gRecaptchaResponse
      fields.gRecaptchaResponse = this.gRecaptchaResponse
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        this.isLoading = true

        try {
          this.$emit('isLoading', true)
          const { data: result } = await Api().post('/send/message/gd', fields)
          this.messageReponse = result
          this.popupResponseOpen = true
        } catch (err) {
          console.log(err)
        } finally {
          this.isLoading = false
          this.$emit('isLoading', false)
        }
      }
    },
    handleSuccess () {
      console.log('success')
    },
    handleError () {
      console.log('error')
    },
    selectValue (value) {
      this.eMessage.speed = value
      this.closeDropdown()
    },
    closePopupResponse () {
      this.messageReponse = null
      this.popupResponseOpen = false
    },
    openDropdown (name) {
      this.showDepartmentDropdown = true
    },
    closeDropdown () {
      this.showSubjectDropdown = false
      this.showDepartmentDropdown = false
    },
    formatTelephone (input) {
      var inputLow = input.toLowerCase()
      var phonenumber = ''
      for (var i = 0; i < inputLow.length; i++) {
        var character = inputLow.charAt(i)
        switch (character) {
          case '0':
            phonenumber += '0'
            break
          case '1':
            phonenumber += '1'
            break
          case '2':
            phonenumber += '2'
            break
          case '3':
            phonenumber += '3'
            break
          case '4':
            phonenumber += '4'
            break
          case '5':
            phonenumber += '5'
            break
          case '6':
            phonenumber += '6'
            break
          case '7':
            phonenumber += '7'
            break
          case '8':
            phonenumber += '8'
            break
          case '9':
            phonenumber += '9'
            break
          case 'a':
          case 'b':
          case 'c':
            phonenumber += '2'
            break
          case 'd':
          case 'e':
          case 'f':
            phonenumber += '3'
            break
          case 'g':
          case 'h':
          case 'i':
            phonenumber += '4'
            break
          case 'j':
          case 'k':
          case 'l':
            phonenumber += '5'
            break
          case 'm':
          case 'n':
          case 'o':
            phonenumber += '6'
            break
          case 'p':
          case 'q':
          case 'r':
          case 's':
            phonenumber += '7'
            break
          case 't':
          case 'u':
          case 'v':
            phonenumber += '8'
            break
          case 'w':
          case 'x':
          case 'y':
          case 'z':
            phonenumber += '9'
            break
          case '-':
          case ' ':
          case '(':
          case ')':
            phonenumber += ''
            break
          default:
            phonenumber += character
        }
      }
      return phonenumber
    }
  }
}
</script>

<style lang="scss" scoped>
 .fki-email-message-response{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      width:100%;
      flex-direction: column;
      font-size: 14px;
      padding: 12px 24px;
      &.error {
        background-color: #ff0000;
      }
      &.success {
        background-color: #628f2f;
      }
      p {
        font-size: 14px;
      }
      h3 {
        color:#fff;
        text-align: center;
        font-size: 16px;
        &.error {
          color: #ff0000;
        }
      }
    }
.fki-divider {
    height: 1px;
    background: #959595;
    opacity: .2;
    width: 100%;
}
.fki-contact-wrapper {
    display: flex;
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .fki-contact-box {
      overflow-y: scroll;
      position: relative;
      height: 100%;
      padding:30px 0 0 0;
      min-height: 100px;
      max-width: 924px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .fki-form-container {
      display: flex;
      margin-top: 30px;
      clear: none;
      width: 100%;
      height: 100%;
      min-width: 300px;
      position: relative;
      overflow: hidden;
      min-height: 100px;
      max-width: 924px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      button {
        width: 85px;
        height: 45px;
        background-color: #E77926;
        color: #fff;
        border: 0;
        outline: 0;
        padding: 10px 15px;
        margin: 10px 0;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        transition: all .55s cubic-bezier(.25,.8,.25,1);
        &:hover {
          background-color: #fff;
          color: #E77926;
          transition: all .55s cubic-bezier(.25,.8,.25,1);
          &.disabled {
           cursor: unset;
            background-color:rgba(115, 115, 115, 0.471);
              color: #fff;
           }
        }
        &.disabled {
          cursor: unset;
          background-color:rgba(115, 115, 115, 0.471);
        }
      }
      h3 {
        padding-top: 5px;
        text-align: left;
        margin: 0;
        text-transform: uppercase;
        color: #fff;
        font-size: 12px;
        text-align: left;
        letter-spacing: 1px;
        margin: 0 0 8px;
      }
      p {
        text-align: left;
      }
      .fki-form-input, input {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        background: #fff;
        color: #757575;
        height: 45px;
        border: 0;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
      }
      .fki-form-textarea, textarea {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        height: 140px;
        background: #fff;
        color: #757575;
        border: 0;
        outline: 0;
        font-size: 14px;
        font-family: Avenir, Helvetica, Arial, sans-serif;
      }
      .fki-dropdown {
        width: 100%;
        padding: 0;
        margin: 10px 0;
        background: #fff;
        color: #757575;
        height: 45px;
        border: 0;
        outline: 0;
        font-size: 14px;
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        position: relative;
        float: left;
        cursor: pointer;
        p {
          color: #757575;
          font-size: 14px;
          margin: 0;
          padding: 13px 10px;
        }
        .fki-dropdown-content {
          opacity: 0;
          max-height: 198px;
          overflow: auto;
          display: none;
          height: 0;
          position: absolute;
          top: 0;
          padding: 8px 0;
          border: 1px solid #eee;
          z-index: 5000;
          box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
          &.open {
            display: block;
            opacity: 1;
            height: auto;
            position: absolute;
            min-height:45px;
            background: #fff;
            width: 100%;
            margin: 0;
            padding: 8px 0;
            left: 0;
            border: 1px solid #d3d3d3;
            box-sizing: border-box;
            top: 0;
          }
        }
        .fki-option {
          color: #757575;
          font-size: 14px;
          margin: 3px 0 0;
          padding: 8px;
          cursor: pointer;
          &:hover {
            cursor: pointer;
            background: #eee;
          }
        }
      }
      .right-arrow:before {
        position: absolute;
        color: #757575;
        right: 8px;
        top: 12px;
        content: '\25BC';
      }
      .fki-dropdown-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 4999;
        cursor: auto !important;
      }
       .row {
      display: flex;
      width: 100%;
      text-align: left;
      justify-content: space-between;
      .col {
        display: flex;
        width: 50%;
        padding-right: 20px;
        flex-direction: column;
        &.col-right {
          padding-right: 0;
          padding-left: 20px;
        }
      }
    }
    }

    .fki-contact-info {
      clear: none;
      padding-right: 30px;
      display: flex;
      width:50%;
      flex-direction: column;
      justify-content: flex-start;
      h3 {
        text-align: left;
        text-transform: uppercase;
        color: #fff;
        font-size: 14px;
        padding-top: 5px;
        margin: 0 0 5px;
        letter-spacing: 1px;
      }
      a {
        text-align: left;
        color: #fff;
        font-size: 14px;
        margin: 0;
        letter-spacing: 1px;
        padding-bottom: 10px;
        text-decoration: none;
      }
    }
    .fki-contact-box-container {
      overflow-y: scroll;
      padding:25px 30px;
      min-height: 100px;
      max-width: 924px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .row {
      display: flex;
      width: 100%;
      text-align: left;
      justify-content: space-between;
      .col {
        display: flex;
        width: 50%;
        padding-right: 20px;
        flex-direction: column;
        &.col-right {
          padding-right: 0;
          padding-left: 20px;
        }
      }
    }
    form {
      width:100%;
    }
    .recaptch {
      padding: 10px 0;
      max-height: 80px !important;
      overflow: hidden;
      width: 100%;
    }
    .error {
      color: #ff0000;
      font-size: 14px;
      font-weight: bold;
      margin: 5px 0;
    }
}
</style>
